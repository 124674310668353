import { Contact } from 'components/ui/contact/contact';
import { Hours } from 'components/ui/hours/hours';
import { Location } from 'components/ui/location/location';
import './contact-page.css'

export const ContactPage = () => {
    return (
        <div className='contact-page-main'>
            <div className='contact-container'>
                <div className="section-title">CONTACT US</div>
                <div className='contact-main-content'>
                    <div className='contact-and-hours'>
                        <Contact />
                        <Hours />
                    </div>
                    <Location />
                </div>
            </div>
        </div >
    );
}