import './service-card.css';
interface ServiceCardProps {
    service: string,
    price: string,
    description?: string,
}
export const ServiceCard = ({ service, price, description }: ServiceCardProps) => {
    return (
        <div className='service-card-main'>
            <div className='service-card-content'>
                <div className='service'>{service}</div>
                <div className='price'>{price}</div>
            </div>
            <em className='description'>{description}</em>
        </div>
    )
}