import { Artist as ArtistType } from "./constants"
import { Card } from "components/layout/card/card";
import { Gallery } from "components/ui/gallery/gallery";
import { Link } from "react-router-dom";
import 'components/pages/artists/artists.css';

interface ArtistProps {
    artist: ArtistType
}

export const Artist = ({ artist }: ArtistProps) => (
    <div className="main-content">
        <div className="artists-main">
            <div className="artist-backdrop">
                <div className='artist-content'>
                    <Link to='/artists' className="back-link">Back to Artists</Link>
                    <div className="artist-cards-container">
                        <Card description={artist.description} expandable={artist.expandable}>
                            <div className="name-and-image">
                                <div>
                                    <img className="artist-img" src={artist.imgUrl} alt={`artist ${artist.name}`} />
                                </div>
                                <div className="desktop-title">{artist.name}</div>
                                <div className="pronouns-title">({artist.pronouns})</div>
                                <div className="artist-title">{artist.title}</div>
                            </div>
                        </Card>
                        {artist.images &&
                            <div className="artwork-container">
                                <div className="artwork-by-text">Artwork by {artist.name.split(' ')[0]}</div>
                                <Gallery thumbnailPosition="bottom" imagesModules={[artist.images]} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div >
)