import { Link } from "react-router-dom";
import ScissorsIcon from 'assets/icons/scissors.png';
import HairColorIcon from 'assets/icons/hair-color-icon.png';
import './book-button.css';

export const BookButton = () =>
    <Link
        to='https://defianthair.glossgenius.com/services'
        className="book-button">
        <div className='book-button-text'>Book Now</div>
        <img className="scissors-icon" src={ScissorsIcon} alt="scissors icon" />
        <img className="hair-color-icon" src={HairColorIcon} alt="hair color icon" />
    </Link>
