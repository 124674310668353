import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import DownIcon from 'assets/icons/down-icon.png';
import LightningIcon from 'assets/icons/lightning.png';
import BlueVine from 'assets/images/vine.png';
import RedVine from 'assets/images/red-vine.png';
import GreenVine from 'assets/images/green-vine.png';
import BlueMobileVine from 'assets/images/vine-semicircle.png';
import RedMobileVine from 'assets/images/red-vine-semicircle.png';
import GreenMobileVine from 'assets/images/green-vine-semicircle.png';
import Logo from 'assets/logos/defiant-hair-black-logo.png';
import { useOnScreen } from 'helpers/custom-hooks';
import { Contact } from 'components/ui/contact/contact';
import { Hours } from "components/ui/hours/hours";
import { BookButton } from "components/ui/book-button/book-button";
import { Location } from "components/ui/location/location";
import './home.css';

export const Home = () => {
    const homeContainer = useRef<null | HTMLDivElement>(null);
    const whoWeAreSection = useRef<null | HTMLDivElement>(null);
    const titleSection = useRef<null | HTMLDivElement>(null);
    const review1 = useRef<null | HTMLDivElement>(null);
    const review2 = useRef<null | HTMLDivElement>(null);
    const review3 = useRef<null | HTMLDivElement>(null);
    const isTitleSectionOnScreen = useOnScreen(titleSection);
    const isWhoWeAreSectionOnScreen = useOnScreen(whoWeAreSection);
    const isReview1OnScreen = useOnScreen(review1);
    const isReview2OnScreen = useOnScreen(review2);
    const isReview3OnScreen = useOnScreen(review3);
    const [animationClassWhoWeAreSection, setAnimationClassWhoWeAreSection] = useState('');
    const [animationClassReview1, setAnimationClassReview1] = useState('');
    const [animationClassReview2, setAnimationClassReview2] = useState('');
    const [animationClassReview3, setAnimationClassReview3] = useState('');
    const [vine, setVine] = useState(BlueVine);
    const [mobileVine, setMobileVine] = useState(BlueMobileVine);

    useEffect(() => {
        const random = Math.floor(Math.random() * 3);
        if (random === 0) {
            setVine(BlueVine);
            setMobileVine(BlueMobileVine);
        } else if (random === 1) {
            setVine(RedVine);
            setMobileVine(RedMobileVine);
        } else if (random === 2) {
            setVine(GreenVine);
            setMobileVine(GreenMobileVine);
        }
    }, []);

    const scrollToNextSection = () => {
        if (isTitleSectionOnScreen && homeContainer.current && whoWeAreSection.current) {
            homeContainer.current.scrollTop = whoWeAreSection.current.scrollHeight;
        }
    }

    useEffect(() => {
        if (isWhoWeAreSectionOnScreen) {
            setAnimationClassWhoWeAreSection('fadeIn');
        }
    }, [isWhoWeAreSectionOnScreen])

    useEffect(() => {
        if (isReview1OnScreen) {
            setAnimationClassReview1('fadeIn');
        }
    }, [isReview1OnScreen]);

    useEffect(() => {
        if (isReview2OnScreen) {
            setAnimationClassReview2('fadeIn');
        }
    }, [isReview2OnScreen]);

    useEffect(() => {
        if (isReview3OnScreen) {
            setAnimationClassReview3('fadeIn');
        }
    }, [isReview3OnScreen]);

    return (
        <div ref={homeContainer} className="home-container">
            <div ref={titleSection} className="home-title-content">
                <div className="vines">
                    <div className="vine-container">
                        <img src={vine} className="vine" alt="rose bush vine" />
                        <img src={mobileVine} className="vine-mobile" alt="rose bush vine" />
                    </div>
                    <div>
                        <img src={Logo} className="mini-logo" alt="defiant hair logo" />
                    </div>
                    <div className="vine-container flipped">
                        <img src={vine} className="vine flipped" alt="rose bush vine" />
                        <img src={mobileVine} className="vine-mobile flipped" alt="rose bush vine" />
                    </div>
                </div>
                <div className="home-sub-container">
                    <div className="sub-heading">
                        <div className="sub-heading-text art">Art</div>
                        <img className="lightning-icon" src={LightningIcon} alt="lightning icon" />
                        <div className="sub-heading-text hair">Hair</div>
                        <img className="lightning-icon" src={LightningIcon} alt="lightning icon" />
                        <div className="sub-heading-text inspo">Inspo</div>
                    </div>
                    <div className="book-button-parent">
                        <BookButton />
                    </div>
                    <div className="scroll-down-container">
                        <img className='scroll-down-icon' onClick={scrollToNextSection} src={DownIcon} alt="scroll down icon" />
                    </div>
                </div>
            </div>
            <div className='page-section who-we-are' ref={whoWeAreSection}>
                <div className={`who-we-are section-title ${animationClassWhoWeAreSection}`}>WHO WE ARE</div>
                <div className={`who-we-are-main-content ${animationClassWhoWeAreSection}`}>
                    <div className="who-we-are-text">Defiant Hair is a vibrant, woman-led hair studio dedicated to inclusivity and sustainability. We specialize in custom color, cuts, and extensions for all hair textures. Rooted in the cultural tapestry of NYC and Philadelphia, we channel the essence of art, fashion, and community into every facet of our establishment.</div>
                </div>
            </div>
            <div className="page-section reviews">
                <div className="section-title">REVIEWS</div>
                <div className="reviews-container">
                    <div ref={review1} className={`review ${animationClassReview1}`}>
                        "Ever since I first stepped into the salon, Lauren and Mari have helped me feel so much more confident about my hair! The decoration, atmosphere, and awesome hair stylists make this place a 10/10!"
                    </div>
                    <div ref={review2} className={`review ${animationClassReview2}`}>
                        "Lauren's knowledge is like no other! She does exactly what I ask of her or provides great suggestions when I am unsure of what I am looking for...I can not recommend Defiant Hair enough!!!"
                    </div>
                    <div ref={review3} className={`review ${animationClassReview3}`}>
                        "I have been going to Lauren for years and am beyond grateful for the way she cares for not just my hair but me in general...You are guaranteed the hair of your dreams while experiencing tranquility in the most beautiful ambience"
                    </div>
                </div>
                <Link className="see-more-reviews-link " target="_blank" to='https://www.google.com/search?hl=en-US&gl=us&q=Defiant+Hair,+3+Fulper+Rd,+Flemington,+NJ+08822&ludocid=1029510400487065599&lsig=AB86z5WhzYCOdtYdb21rA0FWhJit&hl=en&gl=US#lrd=0x89c3f1882e34ee71:0xe498e41319a07ff,1'>See more</Link>
            </div>
            <div className="page-section hours-and-location">
                <div className="section-title">HOURS & LOCATION</div>
                <div className="hours-and-location-content">
                    <Hours />
                    <Location />
                </div>
            </div>
            <div className="page-section contact">
                <div className="section-title">CONTACT US</div>
                <Contact />
            </div>
        </div >
    );
}