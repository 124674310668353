import { RouterProvider, createBrowserRouter, Route, createRoutesFromElements } from "react-router-dom";
import { App } from "app";
import { Home } from 'components/pages/home/home.page';
import { Artists } from 'components/pages/artists/artists.page';
import { ContactPage } from 'components/pages/contact/contact.page';
import { GalleryPage } from "components/pages/gallery/gallery.page";
import { Community } from "components/pages/community/community.page";
import { Policies } from "components/pages/policies/policies.page";
import { ServicesPage } from "components/pages/services/services.page";
import { Artist } from "components/pages/artist/artist.page";
import { artists } from "components/pages/artist/constants";

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<App />}>
            <Route index element={<Home />} />
            <Route path="artists" element={<Artists />} />
            {artists.map((artist, index) => {
                return <Route key={index} path={`artists/${artist.route}`} element={<Artist artist={artist} />} />
            })}
            <Route path="community" element={<Community />} />
            <Route path="policies" element={<Policies />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="gallery" element={<GalleryPage />} />
            <Route path="services" element={<ServicesPage />} />
        </Route>
    )
);

export const Routing = () => {
    return <RouterProvider router={router} />
}