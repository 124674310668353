import './location.css'

export const Location = () => {
    return (
        <div className='location-container'>
            <a className='address-link' href='https://www.google.com/maps/embed/v1/place?key=AIzaSyDke-Qr6cVWLpUlneIWtIojEfQm_iQAgLk&q=Defiant+Hair,Flemington+NJ'>
                3 Fulper Rd. Flemington, NJ
            </a>
            <iframe
                title="defiant hair google maps location"
                loading="eager"
                allowFullScreen
                referrerPolicy="no-referrer-when-downgrade"
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDke-Qr6cVWLpUlneIWtIojEfQm_iQAgLk&q=Defiant+Hair,Flemington+NJ">
            </iframe>
        </div>
    )
}