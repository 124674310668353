import { ServiceCard } from "components/layout/service-card/service-card"
import { Service } from "components/pages/services/services.types"
import './service-section.css';
interface ServiceSectionProps {
    services: Service[],
    title: string,
    firstIcon: string,
    firstIconAlt: string,
    secondIcon: string,
    secondIconAlt: string,
}
export const ServiceSection = ({
    services,
    title,
    firstIcon,
    secondIcon,
    firstIconAlt,
    secondIconAlt
}: ServiceSectionProps) => {

    return (
        <div className="service-section-container">
            <div className="service-section-heading">
                <img className="service-section-icon" src={firstIcon} alt={firstIconAlt} />
                <div className="service-section-title">{title}</div>
                <img className="service-section-icon" src={secondIcon} alt={secondIconAlt} />
            </div>
            <div className="service-section-content">
                {services.map((service) =>
                    <ServiceCard
                        price={service.price}
                        description={service.description}
                        service={service.service} />
                )}
            </div>
        </div>
    )

}