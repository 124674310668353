import { PropsWithChildren, useState } from "react";
import ChevronDown from 'assets/icons/chevron-down.png'
import ChevronUp from 'assets/icons/chevron-up.png'
import './card.css';

interface CardProps {
    description: string;
    expandable?: boolean;
}
export const Card = ({ description, children, expandable }: PropsWithChildren<CardProps>) => {
    const [expanded, setExpanded] = useState(!expandable);

    return (
        <div className='card'>
            {children}
            <div className={`card-body ${expanded || !expandable ? 'expanded' : ''}`}>
                {description}
            </div>
            {expandable &&
                <div className="card-actions">
                    <div className="expand-description-button" onClick={() => setExpanded(!expanded)}>
                        <div className="card-section">
                            {expanded ? 'Hide' : 'Read more'}
                        </div>
                        <div className="card-section">
                            <img className="chevron-img" height='10px' width='10px' src={expanded ? ChevronUp : ChevronDown} alt="chevron" />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}