import RedVine from 'assets/images/red-vine.png';
import Logo from 'assets/logos/DFLogo_transparent_background.png';
import './policies.css'

export const Policies = () => {
    return (
        <div className="policies-main">
            <div className="policies-parent">
                <div className="policies-container">
                    <div className="section-title">POLICIES</div>
                    <div className="policies-main-content">
                        <p className='policy-header'>Product and Goods Refund Policy:</p>
                        <p>At Defiant Hair we take great care in selecting and providing high-quality hair products and goods to enhance your beauty and grooming needs. We aim to ensure your satisfaction with every purchase. To clarify our policy regarding products and goods, please note the following:</p>
                        <ol>
                            <li>
                                <p>No Refunds:</p>
                                <p>We do not offer refunds for products or goods purchased at our salon. Our commitment is to deliver products that meet our quality standards, and we stand by the integrity of our offerings.</p>
                            </li>
                            <li>
                                <p>Exchanges:</p>
                                <p>If, for any reason, you are dissatisfied with a product or good purchased from us, we are happy to facilitate an exchange. To be eligible for an exchange, please adhere to the following conditions:</p>
                                <ul>
                                    <li>The product or good must be in its original, unopened packaging.</li>
                                    <li>The exchange request must be made within 14 days of the original purchase.</li>
                                </ul>
                            </li>
                            <li>
                                <p>Product Inspection:</p>
                                <p>Our team will thoroughly inspect the returned product or good to ensure it meets the above conditions. Once the inspection is complete and approved, we will assist you in selecting a suitable replacement product of equal or lesser value.</p>
                            </li>
                            <li>
                                <p>Proof of Purchase:</p>
                                <p>To initiate an exchange, you must present a valid proof of purchase, such as a receipt or purchase record. This helps us verify the original purchase and process your exchange efficiently.</p>
                            </li>
                        </ol>
                        <p>We are committed to your satisfaction and are here to assist you with any concerns regarding our products or goods. If you have any questions or need assistance with an exchange, please contact us. Our goal is to ensure you have the best possible experience with our salon and our products.</p>
                        <div className="policy-divider-container">
                            <div className="vine-container-policies">
                                <img src={RedVine} className="vine-policies" alt="rose bush vine" />
                            </div>
                            <div className='policy-logo'>
                                <img src={Logo} className="mini-logo-policies" alt="defiant hair logo" />
                            </div>
                            <div className="vine-container-policies flipped">
                                <img src={RedVine} className="vine-policies flipped" alt="rose bush vine" />
                            </div>
                        </div>
                        <p className='policy-header'>Client Satisfaction Policy:</p>
                        <p>At Defiant Hair, our top priority is to ensure our clients leave our salon feeling delighted with their hair services. We understand that sometimes, despite our best efforts, you may have concerns or requests for adjustments. To address such situations, we have established the following client satisfaction policy:</p>
                        <ol>
                            <li>
                                <p>Grace Period for Adjustments:</p>
                                <p>If, for any reason, you are not satisfied with your hair service, we offer a one-week grace period during which you can request adjustments or corrections. We want to ensure you leave our salon completely happy with your hair.</p>
                            </li>
                            <li>
                                <p>Additional Charges:</p>
                                <p>In some cases, additional charges may apply for adjustments or corrections, depending on the nature of the requested changes. The specific charges will be determined at the discretion of our skilled artists and will be discussed with you before any additional work is performed.</p>
                            </li>
                            <li>
                                <p>Clear Communication:</p>
                                <p>Our goal is to provide transparency in all aspects of our services. Before any adjustments or corrections are made, our artists will communicate with you to ensure you understand the changes and any associated charges. Your approval will be sought before proceeding with any additional work.</p>
                            </li>
                            <li>
                                <p>No Refunds:</p>
                                <p>Please note that our policy does not include refunds. Instead, we are committed to working with you to address any concerns and make the necessary adjustments to achieve the look you desire. Our team is dedicated to your satisfaction, and we believe that open communication and collaboration are key to achieving your desired results.</p>
                            </li>
                        </ol>
                        <p>At Defiant Hair we are passionate about delivering exceptional hair services and exceeding your expectations. If you have any questions or concerns regarding our client satisfaction policy, please do not hesitate to reach out to us through email at  defiant.hair.nj@gmail.com. Your happiness is our priority.</p>
                        <div className="policy-divider-container">
                            <div className="vine-container-policies">
                                <img src={RedVine} className="vine-policies" alt="rose bush vine" />
                            </div>
                            <div className='policy-logo'>
                                <img src={Logo} className="mini-logo-policies" alt="defiant hair logo" />
                            </div>
                            <div className="vine-container-policies">
                                <img src={RedVine} className="vine-policies flipped" alt="rose bush vine" />
                            </div>
                        </div>
                        <p className='policy-header'>Salon Cancellation Policy:</p>
                        <p>At Defiant Hair, we strive to provide exceptional service to all our clients, and we understand that sometimes scheduling conflicts may arise. To ensure that we can accommodate the needs of all our valued clients and maintain a smooth appointment schedule, we have implemented the following cancellation policy:</p>
                        <ol>
                            <li>
                                <p>Cancellation Notice:</p>
                                <p>We kindly request that you provide a minimum of 48 hours' notice if you need to cancel or reschedule your appointment. This advance notice allows us to make adjustments to our schedule and accommodate other clients who may be on our waiting list.</p>
                            </li>

                            <li>
                                <p>Late Cancellations:</p>
                                <p>If you must cancel your appointment with less than 48 hours' notice, a late cancellation fee may be applied. This fee is intended to compensate our staff for the reserved time that could have been allocated to other clients. The specific fee amount will be communicated to you at the time of booking.</p>
                            </li>
                            <li>
                                <p>No Shows:</p>
                                <p>A "no show" occurs when a client misses their appointment without any prior notice. If you fail to show up for your appointment without contacting us, we reserve the right to charge a no-show fee or request pre-payment for future appointments.</p>
                            </li>
                            <li>
                                <p>Multiple Late Cancellations or No Shows:</p>
                                <p>Repeated late cancellations or no-show incidents may result in a requirement for pre-payment for any future services. Our goal is to ensure that all clients have a fair opportunity to schedule appointments and receive our services without disruptions.</p>
                            </li>
                            <li>
                                <p>Lateness:</p>
                                <p>Out of respect for our clients, services for those arriving more than 10 minutes late will be honored at the artists discretion. We reserve the right to modify or cancel your appointment in order to stay on time for the rest of our clients.</p>
                            </li>
                            <li>
                                <p>Communication:</p>
                                <p>We understand that unforeseen circumstances can arise, and we encourage open communication. If you have an emergency or an unavoidable situation preventing you from keeping your appointment, please contact us as soon as possible so that we can work together to find a suitable solution.</p>
                            </li>
                        </ol>
                        <p>We appreciate your understanding and cooperation with our salon's cancellation policy. Our aim is to provide the best possible service to all our clients while maintaining an efficient and respectful appointment schedule.</p>
                        <p>Thank you for choosing Defiant Hair for your beauty needs. If you have any questions or require clarification on our policy, please feel free to contact us at <a className='email-link' href="mailto:defiant.hair.nj@gmail.com">defiant.hair.nj@gmail.com</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
}