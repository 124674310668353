import ScissorsIcon from 'assets/icons/scissors.png';
import HairColorIcon from 'assets/icons/hair-color-icon.png';
import HairBrushIcon from 'assets/icons/brush.png';
import StraightRazorIcon from 'assets/icons/straight-razor.png';

export const services = [
    {
        title: 'Cuts',
        firstIcon: ScissorsIcon,
        secondIcon: StraightRazorIcon,
        firstIconAlt: 'scissors icon',
        secondIconAlt: 'straight razor icon',
        services: [
            {
                service: 'Children 12 and under',
                price: '$55',
            },
            {
                service: 'Short Haircut',
                description: 'Sharp edges, clipper fades, and scissor blending on top',
                price: 'Starting at $65',
            },
            {
                service: 'Short detailed cut',
                description: 'Soft, piecey styles with razor layers or blunt scissor cuts. Detailed texturizing for bobs, mullets, bixies, pixies, and more!',
                price: 'Starting at $55+',
            },
            {
                service: 'Long haircut',
                description: 'Anything below the shoulders!',
                price: 'Starting at $55+',
            },
            {
                service: 'Bang trim',
                description: 'For bangs, undercuts, and neckline clean-ups',
                price: '$0',
            }
        ]
    },
    {
        title: 'Color',
        firstIcon: HairColorIcon,
        secondIcon: HairColorIcon,
        firstIconAlt: 'hair color icon',
        secondIconAlt: 'hair color icon',
        services: [
            {
                service: 'Full Custom color',
                price: '$325',
                description: 'Full head of foils or balayage, which may include root shadow/blend, detailed work, toner, and bonding treatment for a flawless finish. Must book blowout separately.'
            },
            {
                service: 'Partial custom color',
                price: '$195+',
                description: 'Partial head of foils or balayage, with focus on face-framing and part line. May include root shadow/blend, detailed work, toner, and bonding treatment for a flawless finish. Must book blowout separately.'
            },
            {
                service: 'Single Process - Root Touch-up',
                price: '$95',
                description: 'Root coverage application of existing hair color. Must book blowout separately.'
            },
            {
                service: 'Single Process - With Gloss',
                price: '$145',
                description: 'Full head hair color application for new clients, color changes, or revitalizing dull color. Perfect for covering or blending gray hair or adding shine and vibrancy. Must book blowout separately.'
            },
            {
                service: 'Toner / Gloss',
                description: 'Enhance existing color, neutralize unwanted tones like brassiness, and add shine to hair. Book every 4-6 weeks to keep highlights or color vibrant and shiny. Must book blowout separately.',
                price: '$35+'
            },
        ]
    },
    {
        title: 'Other',
        firstIcon: HairBrushIcon,
        secondIcon: HairBrushIcon,
        firstIconAlt: 'hair brush icon',
        secondIconAlt: 'hair brush icon',
        services: [
            {
                service: 'Curl Cult® Protein Perm',
                price: 'Price varies',
            },
            {
                service: 'K18 REPAIR™ Treatment',
                description: 'The bond repair treatment the Internet can\'t stop talking about.Clinically proven to reverse damage from bleach, color, chemicals, and heat.Available as an add- on or stand - alone service.',
                price: '$50',
            },
            {
                service: 'Keratin smoothing treatment',
                description: 'Transform your hair with our Lasio® One Day Keratin Treatment. Enjoy frizz-free, strong, and nourished hair with reduced blowout time for 3-4 months. Same-day wash with zero processing time for ultimate convenience.',
                price: '$300',
            },
            {
                service: 'Brazilian Blowout® Treatment',
                description: 'Perfect for frizzy, damaged, or processed hair. Achieve frizz-free, shiny, and manageable hair with body and bounce. Conditions while sealing the cuticle for enhanced color, reduced frizz, and radiant shine.',
                price: '$300+',
            },
            {
                service: 'Blowout - With Extensions',
                price: '$65',
            },
            {
                service: 'Event / Formal Styling',
                description: 'Consultation required—contact to discuss your desired look before booking. Includes updos, curls, detailed styling, or setting for long-lasting results.',
                price: '$110+',
            },
            {
                service: 'Extensions - new install',
                description: 'Sew-in beaded weft hair extensions to add fullness or length to natural hair. Hand-tied and hybrid options available, creating a seamless blend through custom color matching and advanced haircutting techniques. Consultation required to book.',
                price: 'Price varies',
            },
            {
                service: 'Extensions - move up',
                price: 'Price varies',
            },
            {
                service: 'Facial Waxing',
                price: '$20+',
            },
            {
                service: 'Deep Conditioning Treatment',
                price: '$35',
                description: 'Good for the hair, good for the soul! Includes a consultation, a calming shampoo, and a nourishing mask tailored to your needs. Available as an add-on or standalone service.'
            },
            {
                service: 'Revive & Refresh Treatment',
                price: '$55',
                description: 'Bad Hair Days and Mondays got you down? Not easy bein’ green? Restore shine, softness, and strength to your hair with a detox that banishes buildup and brings locks back to life. Perfect for brightening blondes, swimmers, product lovers, and those with hard water.'
            },
            {
                service: 'Full Glam Makeup',
                price: '$115',
                description: 'A full face of makeup including foundation, contour, highlight, blush, lips, and an eye look. Lashes add $10.'
            },
            {
                service: 'Soft Glam Makeup',
                price: '$85',
                description: 'Your face, but enhanced. Includes concealer, blush, lips, and a simple eye look. Lashes add $10.'
            },
            {
                service: 'Festival / Special Effects Makeup',
                price: '$150',
                description: 'Consultation and trial required. For when you\'re truly feeling extra—parties, festivals, Halloween, and more. Lashes add $10.'
            },
            {
                service: 'Consultation',
                price: '$0',
            }
        ]
    },
];