import { useEffect, useState } from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import './gallery.css';

interface GalleryProps {
    imagesModules: any[],
    thumbnailPosition: "top" | "right" | "bottom" | "left" | undefined,
    additionalClass?: string,
    originalHeight?: number
}
export const Gallery = ({ imagesModules, thumbnailPosition, additionalClass, originalHeight }: GalleryProps) => {
    const [images, setImages] = useState<ReactImageGalleryItem[]>([]);

    useEffect(() => {
        const imagesList: ReactImageGalleryItem[] = [];
        imagesModules.forEach((imagesModule) => {
            Object.keys(imagesModule).forEach((key: string) => {
                const image = (imagesModule as any)[key];
                imagesList.push(
                    {
                        original: image,
                        thumbnail: image,
                    }
                )
            })
        })
        setImages(imagesList)
    }, [imagesModules]);

    return <ImageGallery additionalClass={additionalClass} items={images} thumbnailPosition={thumbnailPosition} />;
}