import './community.css';

const organizations = [
    {
        name: 'SAFE in Hunterdon',
        link: 'https://safeinhunterdon.org'
    },
    {
        name: 'Good News Home for Women',
        link: 'https://goodnewshome.org'
    },
    {
        name: 'Strands for Trans',
        link: 'https://strandsfortrans.org'
    },
    {
        name: 'Dresscode Project',
        link: 'https://dresscodeproject.com'
    },
    {
        name: 'Green Circle Salons',
        link: 'https://greencirclesalons.com'
    },
    {
        name: 'Open Door Recovery Center',
        link: 'https://njprevent.com'
    },
    {
        name: 'Safe Harbor',
        link: 'https://safeharborfamilies.org'
    },
]

export const Community = () => {
    return (
        <div className='community-background-container'>
            <div className='community-main'>
                <div className='community-backdrop'>
                    <div className="section-title">Community</div>
                    <div className='community-content'>
                        <div>At Defiant Hair it is important to us that we stand with organizations in our community that make Flemington a better place to live, work, and visit.</div>
                        <br />
                        <div>We are dedicated to raising awareness and support for them through our platform. We are a member of the Dress Code Project Salon Alliance, Strands for Trans Global Network, and a certified Green Circle Salon.</div>
                        <br />
                        <div>As a Green Circle Salon, we proudly uphold certification in sustainability practices, meticulously recycling all waste, ncluding hair. The hair is then repurposed into hair booms for effective oil absorption in the ocean. Our product lines, Kevin Murphy and Davines, exemplify our commitment to sustainability, being cruelty-free and devoid of sulfates and parabens. Furthermore, our dedication extends to utilizing 100% recyclable materials in all our packaging.</div>
                        <br />
                        <div>Please check out these local and national organizations and the amazing things they do!</div>
                        <ul>
                            {organizations.map((organization) => (
                                <li>
                                    <a className='community-link' href={organization.link} rel='noreferrer' target='_blank'>
                                        {organization.name} ({organization.link})
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}