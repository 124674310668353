import { artists } from "components/pages/artist/constants";
import { useNavigate } from "react-router-dom";
import './artists.css';

export const Artists = () => {
    const navigate = useNavigate();
    return (
        <div className="main-content">
            <div className="artists-main">
                <div className="artists-backdrop">
                    <div className='artists-content'>
                        <div className="section-title artists-title">OUR ARTISTS</div>
                        {artists.map((artist, index) => (
                            <div className="artist-button" key={index} onClick={() => navigate(`/artists/${artist.route}`)}>
                                <img className="artist-button-img" src={artist.imgUrl} alt={`artist ${artist.name}`} />
                                <div className="artist-button-title">
                                    {artist.name}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div >
    );
}