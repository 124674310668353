import { BookButton } from 'components/ui/book-button/book-button';
import { ServiceSection } from 'components/ui/service-section/service-section';
import { services } from './constants';
import './services.css';

export const ServicesPage = () => {
    return (
        <div className='services-page-main'>
            <div className='services-container'>
                <div className="section-title">SERVICES</div>
                <BookButton />
                <div className='services-main-content'>
                    {services.map(service => (
                        <ServiceSection
                            title={service.title}
                            services={service.services}
                            firstIcon={service.firstIcon}
                            firstIconAlt={service.firstIconAlt}
                            secondIcon={service.secondIcon}
                            secondIconAlt={service.secondIconAlt} />
                    ))}
                </div>
            </div>
        </div>
    );
}