import { Link, useLocation } from "react-router-dom";
import InstagramIcon from 'assets/icons/instagram.png';
import FacebookIcon from 'assets/icons/facebook.png'
import MailIcon from 'assets/icons/mail.png';
import PhoneIcon from 'assets/icons/rotary-phone.png';
import { useEffect, useState } from "react";
import './footer.css';

export const Footer = () => {
    const location = useLocation();
    const [animateClass, setAnimateClass] = useState('');
    useEffect(() => {
        if (location.pathname === '/') {
            setAnimateClass('fade');
        } else {
            setAnimateClass('');
        }
    }, [location.pathname]);
    return (
        location.pathname !== '/contact' ? <div className={`footer-container ${animateClass}`}>
            <div className="footer-sub-container">
                <Link className="contact-icon-link" to='https://www.instagram.com/defiant.hair/' target="_blank">
                    <img className="home-icon-landing" src={InstagramIcon} alt="scissors icon" />
                </Link>
                <Link className="contact-icon-link" to='https://www.facebook.com/defianthair13' target="_blank">
                    <img className="home-icon-landing" src={FacebookIcon} alt="scissors icon" />
                </Link>
                <a className="contact-icon-link" href="mailto:defiant.hair.nj@gmail.com">
                    <img className="home-icon-landing" src={MailIcon} alt="scissors icon" />
                </a>
                <a className="contact-icon-link" href="tel:+19082563954">
                    <img className="home-icon-landing" src={PhoneIcon} alt="scissors icon" />
                </a>
            </div>
        </div> : null
    );
}
