import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/logos/defiant-hair-black-logo.png';
import { menuLinks } from 'components/layout/navbar/menuLinks';
import MenuLinkIcon from 'assets/icons/skull-gen.png';
import CancelIcon from 'assets/icons/cancel.png';
import { SwipeableDrawer } from '@mui/material';
import BurgerMenu from 'assets/icons/burger-menu.png';
import './navbar.css';

export const NavBar = () => {
  const [mobileDrawer, setMobileDrawerOpen] = useState(false);
  return (
    <div className='container'>
      <Link to='/' className='logo-container'>
        <img src={logo} height='62.5em' width='62.5em' alt='Defiant Hair Logo' />
      </Link>

      <div className='menu-section-mobile'>
        <img
          src={BurgerMenu}
          className='menu-burger'
          onClick={() => setMobileDrawerOpen(true)}
          alt='Menu button' />
        <SwipeableDrawer
          anchor='right'
          open={mobileDrawer}
          onOpen={() => setMobileDrawerOpen(true)}
          onClose={() => setMobileDrawerOpen(false)}
          className='mobile-menu-container'>
          <div className='close-button-container' onClick={() => setMobileDrawerOpen(false)}>
            <img className='close-button' src={CancelIcon} alt='close-menu-button' />
          </div>
          <div className='menu-mobile'>
            {menuLinks.map((menuLink) => {
              return (
                <Fragment key={menuLink.link}>
                  <Link className='menu-item mobile' to={menuLink.link} onClick={() => setMobileDrawerOpen(false)}>
                    <img className='menu-icon' src={MenuLinkIcon} alt='skull' />
                    <div className='mobile-menu-item-text'>
                      {menuLink.text}
                    </div>
                    <img className='menu-icon' src={MenuLinkIcon} alt='skull' />
                  </Link>
                </Fragment>
              )
            })}
          </div>
        </SwipeableDrawer>
      </div>
      <nav className='menu'>
        {menuLinks.map((menuLink) => {
          return (
            <Fragment key={menuLink.link}>
              <Link className='menu-item' to={menuLink.link}>{menuLink.text}</Link>
            </Fragment>
          )
        })}
      </nav>
    </div>
  );
}
