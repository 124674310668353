export const menuLinks = [
    {
        text: 'Artists',
        link: '/artists'
    },
    {
        text: 'Services & Online Booking',
        link: '/services'
    },
    {
        text: 'Policies',
        link: '/policies'
    },
    {
        text: 'Community',
        link: '/community'
    },
    {
        text: 'Contact',
        link: '/contact'
    },
    {
        text: 'Gallery',
        link: '/gallery'
    }
]