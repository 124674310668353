import { Gallery } from "components/ui/gallery/gallery";
import * as imagesDir from 'assets/images/gallery';
import * as laurenGallery from "assets/images/gallery-lauren";
import * as mariGallery from 'assets/images/gallery-mari';
import useWindowDimensions from "helpers/custom-hooks";
import './gallery.page.css';

export const GalleryPage = () => {
    const { width } = useWindowDimensions();
    return (
        <div className="gallery-page-container">
            <div className="gallery-main">
                <div className="section-title">Gallery</div>
                <Gallery imagesModules={[imagesDir, laurenGallery, mariGallery]} thumbnailPosition={width >= 600 ? 'left' : 'top'} />
            </div>
        </div>
    );
}