import InstagramIcon from 'assets/icons/instagram.png';
import FacebookIcon from 'assets/icons/facebook.png'
import MailIcon from 'assets/icons/mail.png';
import PhoneIcon from 'assets/icons/rotary-phone.png';
import { Link } from 'react-router-dom';
import './contact.css'

export const Contact = () => {
    return (
        <div className='contact-main'>
            <a href="tel:+19082563954" className='contact-button'>
                <img className='contact-icon' src={PhoneIcon} alt='phone icon' />
                <div className='contact-text'>(908) 256-3954</div>
            </a>
            <a href="mailto:defiant.hair.nj@gmail.com" className='contact-button'>
                <img className='contact-icon' src={MailIcon} alt='email icon' />
                <div className='contact-text email'>defiant.hair.nj@gmail.com</div>
            </a>
            <Link to='https://www.facebook.com/defianthair13' target='_blank' className='contact-button'>
                <img className='contact-icon' src={FacebookIcon} alt='facebook icon' />
                <div className='contact-text'>View our Facebook</div>
            </Link>
            <Link to='https://www.instagram.com/defiant.hair/' target='_blank' className='contact-button'>
                <img className='contact-icon' src={InstagramIcon} alt='instagram icon' />
                <div className='contact-text'>View our Instragram</div>
            </Link>
        </div>
    );
}