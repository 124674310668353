import { Outlet } from 'react-router-dom';
import { NavBar } from 'components/layout/navbar/navbar';
import { Footer } from 'components/layout/footer/footer';

export const App = () => {
  return (
    <>
      <NavBar />
      <Outlet />
      <Footer />
    </>
  );
}